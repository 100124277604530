// 显示提示窗
function showAlert(el, title, summary, fnName) {
  $(el)
    .children('.alert-title')
    .text(title)
  $(el)
    .children('.alert-summary')
    .text(summary)
  $('#btn-alert').attr('data-fn', fnName)
  $(el).show()
  $('#alert-window').show()
}

// 关闭提示窗
function alertClose(el) {
  try {
    alertTitle = null
    alertSummary = null
  } catch (error) { }

  $(el).hide()
  $('#alert-window').hide()
}

// 确认按钮
function alertConfirm(e) {
  var $this = $(e.target)
  eval($this.attr('data-fn'))
}

/* 自定义方法 */

function bala() {
  var data = {
    name: 'hellow',
    age: 'world'
  }

  // var dataJson = JSON.stringify(data)
  var dataJson = data

  showAlert(
    '#alert1',
    '付款提示',
    '吧啦吧啦吧啦吧啦吧啦吧啦',
    'test(' + dataJson + ')'
  )
}

//确认验收
function test123() {
  $.post('/check/acceptance?orderNo=' + array[0], function (result) {
    if (result.code && result.code == 0) {
      var name = encodeURI('订单完成')
      window.open('/users/order-list?name=' + name, '_self')
    }
  })
}

// 返回订单
function returnOrder() {
  window.location.href = '/users/order-list'
}
